export default function sendEmailContato(form) {
    try {
        window.Email.send({
            Host : "mail.germantech.com.br",
            Username : "suporte@provendascrm.com.br",
            Password : "germantech,123",
            To : `${form.email}`,
            From : "suporte@provendascrm.com.br",
            Subject : "Bem vindo ao Pró-Vendas!!",
            Body : `Olá ${form.nome}!<br>
                    Esses são seus dados para acessar o ambiente de teste do Pró-Vendas:
                    <a href="https://provendas.gtech.site">https://provendas.gtech.site</a><br><br>
                    <b>Login</b>: ${form.email}<br>
                    <b>Senha</b>: ${form.password}<br><br>
                    Em breve entraremos em contato!
                `,
            callback: function done(message) { 
                console.log(message)
            }
        }).then(
            console.log('')
          );
    } catch (e) {
        console.log(e)
    }
}