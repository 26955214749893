import React, {Component} from "react";
import { Container, Row, Col } from "reactstrap";

class Valores extends Component {

  render() {
    return (
      <>
        <div id="valores" className="section element valores-bg">
          <Container className="text-center sobre valores-inner">
            <Row className="justify-content-md-center">
                <Col lg="12" md="12">
                {/* <h1 className="titulo-valores" data-aos="fade-down">Valores</h1> */}
                <Row>
                    <img
                        className="selo-valores"
                        alt="valores"
                        src={require("assets/img/valores.png")}
                    ></img>
                    <img
                        className="image-valores"
                        height="70%"
                        alt="valores"
                        src={require("assets/img/todos-valores.png")}
                    ></img>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Valores;