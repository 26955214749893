/*eslint-disable*/
import React from "react";
import { Button, Container } from "reactstrap";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import { animateScroll as scroll, scroller } from 'react-scroll'
import { Row, Col } from "reactstrap";

function IndexHeader() {
  let pageHeader = React.createRef();

  function scrollToLocation(location) {
    scroller.scrollTo(location, {
      duration: 500,
      delay: 0,
      smooth: true,
      spy: false,
      hashSpy: false,
      ignoreCancelEvents: false,
    })

    scroller.unmount();
  }

  return (
    <>
      <div className="header-img" style={{ height:  window.innerHeight}}>
        <IndexNavbar />
        <div className="container">
          <div data-aos="fade-in" className="header-content">
            <Col lg="5" md="12" sm="12" className="header-texto">
              <b>Está cansado de perder negócios?<br/>
              Ver seus potenciais clientes comprando com o concorrente?<br/></b>
              Conheça agora a ferramenta utilizada pelos campeões de venda!
              <Row lg="12" md="12" sm="12">
                <Col lg="6" md="6" sm="6" className="header-div-buttons">
                  <Button onClick={() => scrollToLocation('demonstracao')} className="header-btn header-btn-left" size="lg" color="info">
                  <div className="btn-inner">
                    Solicite uma demonstração
                  </div>
                  </Button>
                </Col>
                <Col lg="4" md="4" sm="6" className="header-div-buttons">
                  <Button onClick={() => scrollToLocation('ferramentas')} className="header-btn header-btn-right" size="lg" color="info">
                  <div className="btn-inner">
                    Conheça as funcionalidades
                  </div>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg="7" md="12" sm="12">
              <img
                className="header-celular"
                alt="header-celular"
                src={require("assets/img/pro-vendas-celular-header.png")}
              ></img>
              <img
                className="header-mac"
                alt="header-mac"
                src={require("assets/img/pro-vendas-imac-header.png")}
              ></img>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;