import React, {Component} from "react";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import YouTube from 'react-youtube';
import {isMobile} from 'react-device-detect';

class Depoimentos extends Component {

  render() {
    var settings = {
      accessibility: true,
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: isMobile ? 1 : 2,
      slidesToScroll: 1
    };

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0
      }
    };

    return (
      <>
        <div className="path-efeito">
            <svg data-aos="fade-down" className="bg-curve aos-init aos-animate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1319 125.96"><path d="M1076.72,1.77C666.38-10.92,302.44,46.23,0,125.29V126H1319V17.24C1241.07,9.69,1160.26,4.35,1076.72,1.77Z"></path></svg>
        </div>
        <div id="depoimentos" className="element" style={{backgroundColor: '#f9fdff'}}>
          <div className="depoimentos-inner">
            <Container className="text-center sobre">
            <Row className="justify-content-md-center">
                <Col lg="12" md="12">
                <h1 data-aos="fade-down">Depoimentos</h1>
                <h4 data-aos="fade-up"className="depoimentos-subtitle">
                Testado e aprovado por profissionais de vendas que atuam nos mais variados segmentos do mercado. 
                <br/><b className="depoimento-resultado">RESULTADO GARANTIDO E COMPROVADO.</b>
                </h4>
                </Col>
            </Row>
            </Container>
            <Container data-aos="fade-down" className="depoimentos">
              <Slider {...settings}>
                <Col lg="12" md="12" sm="12" className="depoimento-card">
                  <Row lg="12" md="12" sm="12">
                    <Col lg="2" md="2" sm="2">
                      <img
                        className="depoimento-avatar"
                        alt="depoimento-avatar"
                        src={require("assets/img/jeremias.png")}
                      ></img>
                    </Col>
                    <Col lg="10" md="10" sm="10">
                      <Col>
                        <div className="depoimento-nome">Jeremias Oberherr
                          <div className="depoimento-funcao">
                            Empresário e palestrante especialista em fechamento de vendas
                          </div>
                        </div>
                        <div className="blockquotes depoimento-conteudo">
                        "Sou empresário, palestrante e diretor do Instituto Theós de Educação Corporativa. Estou na área de vendas a mais de 25 anos. Sempre orientei meus clientes a usarem um bom CRM, eu porém, tinha muita dificuldade em encontrar um que realmente fosse pensado para a realidade de uma equipe e um gestor de vendas. Essa fase só mudou quando implantei, primeiramente para uso pessoal e depois para minha equipe, o PRÓ-VENDAS. Ganhamos tempo, aumentamos significativamente nossas vendas, e hoje acompanho os resultados de minha equipe de onde eu estiver em tempo real!!"
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </Col>

                <Col lg="12" md="12" sm="12" className="depoimento-card">
                  <Row lg="12" md="12" sm="12">
                    <Col lg="2" md="2" sm="2">
                      <img
                        className="depoimento-avatar"
                        alt="depoimento-avatar"
                        src={require("assets/img/vinicius.png")}
                      ></img>
                    </Col>
                    <Col lg="10" md="10" sm="10">
                      <Col>
                        <div className="depoimento-nome">Marcos Vinicius Dellabeta
                          <div className="depoimento-funcao">
                          Diretor da franquia Onde ir Cidades - Toledo/PR
                          </div>
                        </div>
                        <div className="blockquotes depoimento-conteudo">
                          "Eu tinha a necessidade no início da organizar as minhas prospecções em planilhas ou até mesmo em agendas, mas sempre deixava passar muitas oportunidades.
                          Até então nunca havia ouvido falar sobre CRM.
                          Quando eu comecei a pesquisar sobre esse assunto, vi que existem muitas ferramentas no mercado, testei uma, testei outra, e sempre tinha o problema de que as ferramentas eram muito complexas e o suporte era lento e demorado.
                          Hoje já fazem 04 meses que utilizo a plataforma da GermanTech, o sistema é fácil, rápido, atende extremamente bem a minha demanda, o suporte é rápido e humanizado, sempre que tenho dúvidas ou algum problema, rápido e prontamente me respondem ou me ligam para poder solucionar e Sanar as dúvidas.
                          Indico e recomendo.
                          Alavancou as minhas vendas e o meu faturamento."
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </Col>

                <Col lg="12" md="12" sm="12" className="depoimento-card">
                  <Row lg="12" md="12" sm="12">
                    <Col lg="2" md="2" sm="2">
                      <img
                        className="depoimento-avatar"
                        alt="depoimento-avatar"
                        src={require("assets/img/matheus.png")}
                      ></img>
                    </Col>
                    <Col lg="10" md="10" sm="10">
                      <Col>
                        <div className="depoimento-nome">Matheus Lima
                          <div className="depoimento-funcao">
                          Diretor da Soul Design Estratégico
                          </div>
                        </div>
                        <div className="blockquotes depoimento-conteudo">
                        "O Pró-Vendas é um sistema que vem sendo crucial na minha agência, porque com ele consigo importar as empresas recém abertas e entrar em contato oferecendo meus serviços, o que aumentou as minhas vendas e a minha carteira de clientes!!"
                        </div>
                      </Col>
                    </Col>
                  </Row>
                </Col>
                
              </Slider>
            </Container>
            <Container data-aos="fade-up" className="youtube">
              <YouTube
                className="youtube-iframe"
                videoId="slEf8MZMS-g"
                opts={opts}
              />
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Depoimentos;
