import React, {Component} from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { scroller } from 'react-scroll'

class Sobre extends Component {

  state = {
    btnSelected: 0
  }

  titulo = [
    "Funil de vendas. Gerencie e acompanhe as suas oportunidades.",
    "Campanhas. Atrair e captar clientes de maneira simples.",
    "Controle de retornos. Não deixe os retornos caírem no esquecimento.",
    "Gerencie suas metas. Diga onde você quer chegar que nós iremos te ajudar.",
    "Dashboards interativas. Um jeito fácil de acompanhar seus resultados.",
    "Suporte. Precisou de ajuda? temos uma equipe preparada para lhe atender.",
  ];

  mensagem = [
    "Faça o gerenciamento das suas oportunidades de forma simples e prática. Não perca mais nenhum lead. No Pró-Vendas você poderá acompanhar em qual etapa o cliente se encontra.",
    "Crie e gerencie campanhas para a atração e captação de novos prospects. Com templates pré definidos, você poderá criar uma página em poucos minutos.",
    "O Pró-Vendas trabalha com lembretes de retornos para clientes, com isso sua equipe nunca mais esquecerá de fazer uma ligação para seu cliente.",
    "Com uma meta definida você consegue chegar mais longe. No Pró-Vendas você pode criar metas individuais ou por equipe, definindo elas como semanal, quinzenal, mensal, semestral ou anual.",
    "As dashboards do Pró-Vendas foram desenvolvidas para facilitar a leitura dos dados e o acompanhamento da sua equipe. Você pode visualizar os seus fechamentos, vendas, resultados e também o resumo geral de suas oportunidades, pelo computador ou smartphone.",
    "Mesmo com a simplicidade e facilidade do sistema nós sabemos que sempre surge uma dúvida. Por isso disponibilizamos uma equipe de suporte especializada para dar aquela ajudinha quando necessário.",
  ];

  scrollToLocation(location) {
    scroller.scrollTo(location, {
      duration: 500,
      delay: 0,
      smooth: true,
      spy: false,
      hashSpy: false,
      ignoreCancelEvents: false,
    })

    scroller.unmount();
  }

  render() {
    return (
      <>
        <div id="ferramentas" className="section element">
          <Container className="text-center sobre">
            <Row className="justify-content-md-center">
              <Col lg="12" md="12">
                <h1 className="header-h1" data-aos="fade-down">Conheça o Pró-Vendas</h1>
                <h4 data-aos="fade-up"className="sobre-subtitle">
                O Pró-Vendas é um CRM inteligente desenvolvido com conceito B.I. (business intelligence). Com uma tecnologia exclusiva
                de mercado, o sistema tem a capacidade de buscar potenciais clientes para você, e o mais incrível, de forma automática,
                seja em sua cidade, região ou mesmo em outros estados. Além disso:
                </h4>
              </Col>
            </Row>
          </Container>
          <div data-aos="fade-right" className="section">
            <Container className="text-center">
              <Row data-aos="fade-right">
                <Col lg="3" md="6" sm="12">
                    <Button onClick={() => this.setState({btnSelected: 0})} className={"sobre-btn " + (this.state.btnSelected === 0 ? 'sobre-selected' : '')} color="info">
                        Funil de vendas
                    </Button>

                    <Button onClick={() => this.setState({btnSelected: 1})} className={"sobre-btn " + (this.state.btnSelected === 1 ? 'sobre-selected' : '')} color="info">
                        Campanhas
                    </Button>

                    <Button onClick={() => this.setState({btnSelected: 2})} className={"sobre-btn " + (this.state.btnSelected === 2 ? 'sobre-selected' : '')} color="info">
                        Controle de retornos
                    </Button>

                    <Button onClick={() => this.setState({btnSelected: 3})} className={"sobre-btn " + (this.state.btnSelected === 3 ? 'sobre-selected' : '')} color="info">
                        Gerencie suas metas
                    </Button>

                    <Button onClick={() => this.setState({btnSelected: 4})} className={"sobre-btn " + (this.state.btnSelected === 4 ? 'sobre-selected' : '')} color="info">
                        Dashboards interativas
                    </Button>

                    <Button onClick={() => this.setState({btnSelected: 5})} className={"sobre-btn " + (this.state.btnSelected === 5 ? 'sobre-selected' : '')} color="info">
                        Suporte
                    </Button>

                    <Button onClick={() => this.scrollToLocation('demonstracao')} className="sobre-btn sobre-selected" color="info">
                      Teste gratuitamente
                    </Button>
                
                </Col>

                <Col className="sobre-conteudo-div" lg="4" md="6" sm="12">
                  <div className="sobre-titulo">
                    { this.titulo[this.state.btnSelected] }
                  </div>

                  <div className="sobre-conteudo">
                    { this.mensagem[this.state.btnSelected] }
                  </div>
                
                </Col>

                <Col lg="5" md="12" sm="12">
                  <img
                    style={{ marginRight: '20px' }}
                    className="sobre-mac"
                    alt="sobre-mac"
                    src={require(`assets/img/pro-vendas-sobre-${this.state.btnSelected}.png`)}
                  ></img>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default Sobre;