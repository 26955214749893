import React from "react";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

import { animateScroll as scroll, scroller } from 'react-scroll';

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  function scrollToLocation(location) {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(false);
    scroller.scrollTo(location, {
      duration: 500,
      delay: 0,
      smooth: true,
      spy: false,
      hashSpy: false,
      ignoreCancelEvents: false,
    })
    scroller.unmount();
  }

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 99
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 100
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/index"
              id="navbar-brand"
            >
            <img
              alt="..."
              width="100%"
              className="pro-vendas-logo"
              src={require("assets/img/pro-vendas-logo.png")}
            ></img>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar className="navbar-itens">
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                >
                  <p onClick={() => scrollToLocation('ferramentas')}>Início</p>
                </NavLink>
              </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                  >
                      <p onClick={() => scrollToLocation('ferramentas')}>Ferramentas</p>
                  </NavLink>
                </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                >
                  <p onClick={() => scrollToLocation('demonstracao')}>Teste gratuitamente</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                >
                  <p onClick={(e) => {
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(false);
                    scroll.scrollToBottom()
                  }}>Contato</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://provendas.gtech.site"
                >
                  <p>Login</p>
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <i className="now-ui-icons design_app mr-1"></i>
                  <p>Components</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/index" tag={Link}>
                    <i className="now-ui-icons business_chart-pie-36 mr-1"></i>
                    All components
                  </DropdownItem>
                  <DropdownItem
                    href="https://demos.creative-tim.com/now-ui-kit-react/#/documentation/introduction?ref=nukr-index-navbar"
                    target="_blank"
                  >
                    <i className="now-ui-icons design_bullet-list-67 mr-1"></i>
                    Documentation
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {/* <NavItem>
                <NavLink
                  href="https://www.facebook.com/provendas"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Pró vendas
                </UncontrolledTooltip>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
