import React from "react";

import IndexHeader from "components/Headers/IndexHeader.js";
import Sobre from "./home-sections/Sobre.js";
import Depoimentos from "./home-sections/Depoimentos.js";
import Valores from "./home-sections/Valores.js";
import Demonstracao from "./home-sections/Demonstracao.js";
import Footer from "components/Footers/Footer.js";

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="">
        <IndexHeader />
        <div className="main">
          <Sobre />
          <Depoimentos />
          <Valores />
          <Demonstracao />
        </div>
        <Footer />

      </div>
      <img
          alt="wpp-fab"
          onClick={() => window.open("https://wa.me/5545998498928")}
          className="wpp-fab"
          src={require("assets/img/wpp.png")}
      ></img>
    </>
  );
}

export default Index;
