import React, { Component } from "react";
import {
  Alert,
  Row,
  Button,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col
} from "reactstrap";

import maskTelefone from "../../helpers/masks";
import sendEmailContato from "../../helpers/sendEmail";
import { maskCnpj, maskCpf, validarCnpj, validarCpf } from "../../helpers/masks";
const axios = require("axios");
const Recaptcha = require("react-recaptcha");

let form = {
  nome: "",
  telefone: "",
  email: "",
  cnpj: "",
  password: "",
  observacao: ""
};

class Demonstracao extends Component {
  state = {
    // url: 'http://192.168.0.155:3000',
    url: 'https://api.provendas.gtech.site',
    sucessVisible: false,
    errorVisible: false,
    sucessMsg: '',
    errorMsg: '',
    firstFocus: false,
    lastFocus: false,
    hash: "ZPlVW",
    hashContrato: "NYQwN",
    isCreateContrato: false,
    btnCadastrar: "TESTAR GRATUITAMENTE",
    recaptchaOk: false,
    recaptchaToken: "",
    btnCadastrarDisabled: false,
    btnDemonstracaoDisabled: true
  };

  resetForm = () => {
    form = {
        nome: "",
        telefone: "",
        email: "",
        cnpj: "",
        password: "",
        observacao: ""
    };
    this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(this.state.isCreateContrato, false), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});
  };

  btnDemonstracao = () => {
    if (this.state.isCreateContrato) {
        this.setState({
          isCreateContrato: false,
          btnCadastrar: "TESTAR GRATUITAMENTE",
          btnCadastrarDisabled: this.isBtnCadastrarDisabled(false), 
          btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled(false)
        });
    } else {
      document.getElementById("submit-btn").click();
    }
  };

  btnCadastro = () => {
    console.log(form)
    if (this.state.isCreateContrato) {
      document.getElementById("submit-btn").click();
    } else {
      this.setState({
        isCreateContrato: true,
        btnCadastrar: "COMECE AGORA",
        btnCadastrarDisabled: this.isBtnCadastrarDisabled(true), 
        btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled(true)
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.isCreateContrato) {
      if(form.cnpj.length === 18) {
        if(!validarCnpj(form.cnpj)) {
          this.handleError('Cnpj inválido');
          return;
        }
      } else {
        if(!validarCpf(form.cnpj)) {
          this.handleError('Cpf inválido');
          return;
        }
      }
      this.setState({btnCadastrarDisabled: true});
    } else
      this.setState({btnDemonstracaoDisabled: true});

    let tel = form.telefone.replace(/\D/g, "");
    let cnpj = form.cnpj.replace(/\D/g, "");
    if(!this.state.isCreateContrato) {
        axios.post(`${this.state.url}/campanhas/create_oportunidade?id=${this.state.hash}&telefone=${tel}&nome=${form.nome}&email=${form.email}`)
          .then((response) => {
            this.resetForm();
            document.getElementById("form").reset();
            this.handleSuccess('Enviado com sucesso, logo entraremos em contato para agendar a demonstração.');
          })
          .catch((err) => {
            this.setState({btnDemonstracaoDisabled: false});
            console.log(err.message);
          });

    } else {
        axios.post(`${this.state.url}/contratos/criar_contrato_site?telefone=${tel}&nome=${form.nome}&email=${form.email}&cpf_cnpj=${cnpj}&senha=${form.password}`)
          .then((response) => {
            window.open('https://provendas.gtech.site','_blank');
            axios.post(`${this.state.url}/campanhas/create_oportunidade?id=${this.state.hashContrato}&telefone=${tel}&nome=${form.nome}&email=${form.email}&cpf_cnpj=${cnpj}&observacoes=${form.observacao}`)
            .then((response) => {
              sendEmailContato(form);
              this.resetForm();
              document.getElementById("form").reset();
              this.handleSuccess('Cadastro realizado com sucesso.');
            })
            .catch((err) => {
              this.setState({btnCadastrarDisabled: false});
              console.log(err.message);
            });
            })
          .catch((err) => {
            this.setState({btnCadastrarDisabled: false});
            if(err.message === 'Request failed with status code 422')
              this.handleError('Email já está em uso.');
            if(err.message === 'Request failed with status code 406')
              this.handleError('Cnpj já está em uso.');
          });
    }
  };

  handleSuccess = (msg) => {
    this.setState({ sucessVisible: true, sucessMsg: msg });
    setTimeout(() => {
      this.setState({ sucessVisible: false });
    }, 4000);
  }

  handleError = (msg) => {
    this.setState({ errorVisible: true, errorMsg: msg });
    setTimeout(() => {
      this.setState({ errorVisible: false });
    }, 4000);
  }

  reacaptchaCallback = e => {
    this.setState({ recaptchaOk: true, recaptchaToken: e, btnCadastrarDisabled: this.isBtnCadastrarDisabled(true, true), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled() });
  };

  isBtnCadastrarDisabled = (isCreateContrato = this.state.isCreateContrato, recaptchaOk = this.state.recaptchaOk) => {
    try {
        if(!isCreateContrato)
            return false;
        if(form.nome.length > 3 && form.telefone.length > 13 && form.email.length > 0 && this.validateEmail(form.email)
            && form.cnpj.length > 13 && form.password.length > 5 && recaptchaOk) 
            return false;
        return true;
    } catch (err) {
        return true
    }
  }

  isBtnDemonstracaoDisabled = (isCreateContrato = this.state.isCreateContrato) => {
    try {
        if(isCreateContrato)
            return false;
        if(form.nome.length > 2 && form.telefone.length > 13 && form.email.length > 0 && this.validateEmail(form.email)) 
          return false;
        return true;
    } catch (err) {
        return true
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    return (
      <>
        <div id="demonstracao" className="section element valores-bg">
          <Container className="text-center demonstracao-inner sobre">
            <Row lg="12" md="12" className="justify-content-md-center">
              <h1 className="titulo-valores" data-aos="fade-down">
                Quero CONHECER o Pró-Vendas
              </h1>
              <Row
                data-aos="fade-right"
                style={{ textAlign: "center", width: "100%" }}
              >
                <Col>
                  <Form
                    data-aos="fade-up"
                    id="form"
                    className="form form-demonstracao"
                    onSubmit={this.handleSubmit}
                  >
                    <CardBody>
                      <Row>
                        <Col
                          sm="12"
                          md="8"
                          lg="8"
                          className="offset-md-2 offset-lg-2"
                        >
                          <InputGroup
                            className={
                              "no-border input-lg" +
                              (this.state.firstFocus
                                ? " input-group-focus"
                                : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              className="trabalhe-input"
                              placeholder="Nome"
                              type="text"
                              onChange={e => {
                                form.nome = e.target.value;
                                this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});
                              }}
                              maxLength={60}
                              required
                              onFocus={() =>
                                this.setState({ firstFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ firstFocus: false })
                              }
                            ></Input>
                          </InputGroup>
                          <InputGroup
                            className={
                              "no-border input-lg" +
                              (this.state.lastFocus ? " input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-phone"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              onChange={e => {
                                e.target.value = maskTelefone(e.target.value);
                                form.telefone = e.target.value;
                                this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});
                              }}
                              className="trabalhe-input"
                              placeholder="Telefone"
                              maxLength={15}
                              required
                              type="text"
                              onFocus={() => this.setState({ lastFocus: true })}
                              onBlur={() => this.setState({ lastFocus: false })}
                            ></Input>
                          </InputGroup>

                          <InputGroup
                            className={
                              "no-border input-lg" +
                              (this.state.lastFocus ? " input-group-focus" : "")
                            }
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-at"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              onChange={e => {
                                form.email = e.target.value;
                                this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});
                              }}
                              className="trabalhe-input"
                              placeholder="Email"
                              maxLength={60}
                              type="text"
                              required={this.state.isCreateContrato}
                              onFocus={() => this.setState({ lastFocus: true })}
                              onBlur={() => this.setState({ lastFocus: false })}
                            ></Input>
                          </InputGroup>

                          {this.state.isCreateContrato ? (
                            <InputGroup
                              className={
                                "no-border input-lg" +
                                (this.state.lastFocus
                                  ? " input-group-focus"
                                  : "")
                              }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-briefcase"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={e => {
                                  if(e.target.value.length <= 14) {
                                    e.target.value = maskCpf(e.target.value);
                                  } else {
                                    e.target.value = maskCnpj(e.target.value);
                                  }
                                  form.cnpj = e.target.value;
                                  this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});
                                }}
                                className="trabalhe-input"
                                placeholder="CPF/CNPJ"
                                required={this.state.isCreateContrato}
                                maxLength={18}
                                type="text"
                                onFocus={() =>
                                  this.setState({ lastFocus: true })
                                }
                                onBlur={() =>
                                  this.setState({ lastFocus: false })
                                }
                              ></Input>
                            </InputGroup>
                          ) : (
                            []
                          )}

                          {this.state.isCreateContrato ? (
                            <InputGroup
                              className={
                                "no-border input-lg" +
                                (this.state.lastFocus
                                  ? " input-group-focus"
                                  : "")
                              }
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                onChange={e => {
                                    form.password = e.target.value;
                                    this.setState({btnCadastrarDisabled: this.isBtnCadastrarDisabled(), btnDemonstracaoDisabled: this.isBtnDemonstracaoDisabled()});   
                                }}
                                className="trabalhe-input"
                                placeholder="Senha"
                                required={this.state.isCreateContrato}
                                maxLength={50}
                                type="password"
                                onFocus={() =>
                                  this.setState({ lastFocus: true })
                                }
                                onBlur={() =>
                                  this.setState({ lastFocus: false })
                                }
                              ></Input>
                            </InputGroup>
                          ) : (
                            []
                          )}

                          {this.state.isCreateContrato ? (
                            <InputGroup
                              className={
                                "no-border input-lg" +
                                (this.state.lastFocus
                                  ? " input-group-focus"
                                  : "")
                              }
                            >
                              <Input
                                onChange={e => {
                                  form.observacao = e.target.value;
                                }}
                                className="trabalhe-input"
                                style={{borderRadius: '20px'}}
                                placeholder="Fale um pouco sobre você"
                                maxLength={200}
                                type="textarea"
                                onFocus={() =>
                                  this.setState({ lastFocus: true })
                                }
                                onBlur={() =>
                                  this.setState({ lastFocus: false })
                                }
                              ></Input>
                            </InputGroup>
                          ) : (
                            []
                          )}

                          {this.state.isCreateContrato ? (
                            <Recaptcha
                              className="recaptcha-div"
                              sitekey="6LfK_N8UAAAAALpJGENW9gNA1YrhEtXF01SZXLUo"
                              render="explicit"
                              verifyCallback={this.reacaptchaCallback}
                              hl="pt-BR"
                            />
                          ) : (
                            []
                          )}
                        </Col>
                      </Row>
                      <Button
                        id="submit-btn"
                        color="info"
                        style={{ visibility: "hidden", position: "absolute" }}
                        type="submit"
                        size="lg"
                      >
                        submit
                      </Button>
                    </CardBody>
                    <Col className='div-btnform'>
                    <Button
                      className={`
                        ${this.state.btnDemonstracaoDisabled
                          ? "btn-round trabalhe-btn btn-disabled"
                          : "btn-round trabalhe-btn"}
                        ${this.state.isCreateContrato
                          ? ""
                          : "btn-lighter"}
                      `}
                      color="info"
                      disabled={this.state.btnDemonstracaoDisabled}
                      onClick={() => {
                        this.btnDemonstracao();
                      }}
                      size="lg"
                    >
                      SOLICITAR DEMONSTRAÇÃO
                    </Button>
                    <Button
                      className={`
                        ${this.state.btnCadastrarDisabled
                          ? "btn-round trabalhe-btn btn-disabled"
                          : "btn-round trabalhe-btn"}
                        ${this.state.isCreateContrato
                          ? "btn-lighter"
                          : ""}
                      `}
                      color="info"
                      disabled={this.state.btnCadastrarDisabled}
                      onClick={() => {
                        this.btnCadastro();
                      }}
                      size="lg"
                    >
                      {this.state.btnCadastrar}
                    </Button>
                    </Col>
                  </Form>
                </Col>
              </Row>

              <Alert
                className="alert-contato"
                color="success"
                isOpen={this.state.sucessVisible}
              >
                { this.state.sucessMsg }
              </Alert>

              <Alert
                className="alert-contato"
                color="danger"
                isOpen={this.state.errorVisible}
              >
                { this.state.errorMsg }
              </Alert>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Demonstracao;
